.ImageSingleTeamInfo {
  max-width: 90%;
  margin: auto;
  object-fit: scale-down;
  object-position: 50% 50%;
  height: 100%;
  position: relative;
  display: block;

  justify-content: center;
}

.ImageSingleRobotInfo {
  max-width: 100%;
  margin: auto;
  object-fit: cover;
  object-position: 50% 50%;
  height: 100%;
  position: relative;
  display: block;

  justify-content: center;
}

.ImageTeamPhoto {
  max-width: 90%;
  margin: auto;
  object-fit: contain;
  object-position: 50% 50%;
  height: 100%;
  position: relative;
  display: block;
  justify-content: center;
}
