/* Slideshow */

.slideshow {
  margin: 0 auto;
  overflow: hidden;
  max-width: 100%;
  max-height: 50%;
}

.slideshowSlider {
  white-space: nowrap;
  transition: ease 1000ms;
}

.slide {
  display: inline-block;

  height: 600;
  width: 100%;
  border-radius: 40px;
}

/* Buttons */

.slideshowDots {
  text-align: center;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.slideshowDot {
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 50%;

  cursor: pointer;
  margin: 15px 7px 0px;

  background-color: white;
}

.slideshowDot.active {
  background-color: red;
}

.ImageSpecs {
  object-fit: cover;
}

.slidecontainer {
  position: relative;
  text-align: center;
}

.centered {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ImageSingleRobotInfo {
  max-width: 100%;
  margin: 0;
  object-fit: crop;
  object-position: 50% 50%;
  height: 300px;
  width: 100%;
  position: relative;
  display: block;
  justify-content: center;
}
