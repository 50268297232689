.MuiImageListItemBar-positionBelow {
  color: red;
}

.MuiImageListItemBar-titleWrapBelow {
  background-color: black;
}

.MuiImageListItemBar-actionIcon {
  background-color: black;
}

.ImageSpecs {
  max-width: 100%;
  max-height: 75%;
}

.MuiImageListItem-root {
  object-fit: contain;
}
